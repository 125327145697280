html, body, #app, .App, .login-form{
  height:100%
}
.btn-info{
  background-color:#4da092;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.disable-div,
.loading{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  z-index: 2;
}
.loading{
  background-image: url('/minifindstore_spin.gif');
  background-repeat: no-repeat;
  background-position: center;
}



.mt-0{
  margin-top: 0!important;
}
.mb-1-5{
  margin-bottom:1.5rem!important;
}
#wrapper {
  display: flex;
}
.rotate-n-15 {
  transform: rotate(-15deg);
}
.sidebar-brand-icon{
  font-size:2rem;
}
.static-top{
  height:4.375rem;
}
.sidebar {
  width: 12.5rem;
  min-height: 100vh;
  background-color:#252130;
}
.o-auto{
  overflow:auto;
}
.soft-description{
  font-style: italic;
  color:#888;
}
.sidebar hr{
  border-top-color:rgba(255, 255, 255, 0.3); 
}
.sidebar a{
  color:white;
}

.sidebar .sidebar-brand {
  height: 4.375rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1.5rem 1rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .05rem;
  z-index: 1;
  background: #e15153;
  color:white;
}
.sidebar .sidebar-heading,
.sidebar .nav-item{
  text-align: left;
}

.sidebar .sidebar-heading{
  color:#e5b51f;
}

.sidebar .sidebar-heading,
.sidebar .nav-item{
  padding: 0 20px;
}
.sidebar .nav-link span{
  vertical-align: middle;
}

#wrapper #content-wrapper {
  background-color: #f8f9fc;
  width: 100%;
  overflow-x: hidden;
}
.bg-gradient-primary {
  background-color: #4e73df;
  background-image: linear-gradient(180deg,#4e73df 10%,#224abe 100%);
  background-size: cover;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.d-flex {
  display: flex!important;
}
.flex-column {
  flex-direction: column!important;
}

.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: #fff;
  background: rgba(90,92,105,.5);
  line-height: 46px;
}
.sidebar-heading{
  font-weight: bold;
}
.sidebar hr.sidebar-divider {
  margin: 1rem;
}
.form-group input[type="file"]{
  border-radius: 0.5rem;
}
table.small-p td,
table.small-p th
{
  padding:0.3rem;

}
.disclaimer{
  color: rgba(255,255,255,0.7);
}