
.login-form {
  background: #252130;
}

.login-form  .card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.login-form .card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.login-form .card-signin .card-body {
  padding: 2rem;
}

.login-form .form-signin {
  width: 100%;
}

.login-form .form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.login-form .form-group {
  position: relative;
  margin-bottom: 1rem;
}

.login-form .form-group input {
  height: auto;
  border-radius: 2rem;
}

.login-form .form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.login-form .form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.login-form .form-label-group input::-moz-placeholder {
  color: transparent;
}

.login-form .form-label-group input::placeholder {
  color: transparent;
}

.login-form .form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.login-form .form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}
.login-form .alert{
  border-radius: 2rem!important;
}